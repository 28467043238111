import {useState, useEffect} from 'react';
import ChecklistForm from './checklistForm';

import './App.css';
import axios from 'axios';

const implantation_code = 'AKfycbztanCyCMoP1Etz922AgXY0znQ7jp6PxXvq33oMO_ecYYNTWJIGO5l7bKpZwYmvNdsSwQ'
const baseUrl = 'https://script.google.com/macros/s/'+implantation_code+'/exec?page=1&column=6'


export type TarefaType = {
  checklist_id:number,
  desc: string,
  item_id: string,
  checked?: boolean
}

export type ChecklistType = {
  id: number,
  nome?: string,
  tarefas?: TarefaType[],
}

function App() {
  const [checklists, setChecklists] = useState<ChecklistType[]>([],);
  const [activeChecklistId, setActiveChecklistId] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(()=>{
    axios.get(baseUrl)
      .then(res => {
        const checklists = res.data.data.checklists.filter((checklist : ChecklistType ) => checklist.id !== 0);
        setChecklists(checklists);
      })
  }, []); 


  const getTarefas = async (checklist : ChecklistType) => { 
    axios.get(baseUrl + '&checklist_id=' + checklist.id)
      .then(res => {
        const newChecklistsState : ChecklistType[] = checklists.map(checklistAux => {
          if (checklistAux['id'] === checklist.id) {
            return {...checklist, tarefas: res.data.data.tarefas};
          }
    
          return checklistAux;
        });
        setChecklists(newChecklistsState);
      })
  };

  const handleCheckListForm = (checklist : ChecklistType) => {
    getTarefas(checklist).then(() => {
      setActiveChecklistId(checklist.id);
      setModalIsOpen(true);
    });
    
  };

  return (
      <div className='min-h-screen bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-5 flex justify-center'>
        <div className=''>
          <h1 className='inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200'>CHECKLISTS</h1>
          <ul className='max-w-md divide-y divide-gray-200 dark:divide-gray-700'> 
            {checklists.map( 
              (checklist, index) => 
                <li className='py-3' key = { index } >
                  <div className='flex items-center space-x-4 justify-between'>
                    <span className='w-1/3 md:w-1/2'>{ checklist['nome'] }</span>
                    <div className='inline-flex rounded-md w-2/3 md:w-1/2' role='group'>
                      <button 
                        className='inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white' 
                        onClick={() => handleCheckListForm(checklist)}
                      >
                        <svg className="w-4 h-4 dark:text-white mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                          Preencher
                      </button>
                      <button
                        className='inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white'
                      >
                        <svg className="w-4 h-4 dark:text-white mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"></path></svg>
                        Logs
                      </button>
                    </div>
                  </div>
                </li>
              ) }
            </ul>
        </div>
        
        <ChecklistForm modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} activeChecklistId={activeChecklistId} checklists={checklists}></ChecklistForm>
      </div>
  );
}

export default App;
