import { ChangeEvent, useState, useEffect, useCallback } from 'react';

import ReactModal from 'react-modal';
import { ChecklistType } from './App';

import axios from 'axios';

import './App.css';

const implantation_code = 'AKfycbys6oxDN7WjkLK4QQzX_Mf0ALzUBDZKOck_1MfkUr-9xerRAR789DIuIK2RgbbYPMMNOg'
const baseUrl = 'https://script.google.com/macros/s/'+implantation_code+'/exec'



type ChecklistFormProps = {
    modalIsOpen: boolean,
    setModalIsOpen: any,
    activeChecklistId: number,
    checklists: ChecklistType[],
}

type ChecklistlistTypeSubmit = {
    checklist: ChecklistType,
    time: string,
    author: string,
}


function ChecklistForm({modalIsOpen, setModalIsOpen, activeChecklistId, checklists}:ChecklistFormProps) {
    const [checklistSubmit, setChecklistSubmit] = useState<ChecklistlistTypeSubmit>();

    const getCurrentChecklist = useCallback(() : ChecklistType => {
        return checklists.filter((checklist : ChecklistType ) => checklist.id === activeChecklistId)[0];
    }, [checklists, activeChecklistId]);

    useEffect(()=>{
        const currentChecklist = getCurrentChecklist()
        setChecklistSubmit({
            time: Date().toLocaleString(),
            author: 'Maurício Bueno',
            checklist: currentChecklist,
        })
    }, [checklists, getCurrentChecklist]); 

    const handleCheckTask = (e: ChangeEvent) => {
        
        const { value, checked} = (e.target as HTMLInputElement);
        console.log(parseInt(value));
        if(checklistSubmit){
            const newCheckListSubmitTarefas = checklistSubmit.checklist.tarefas?.map((tarefa) => {
                if((parseInt(value) as number) === (parseInt(tarefa.item_id) as number)){
                    return {
                        ...tarefa,
                        checked: checked,
                      };
                }
                return tarefa
            } );
            setChecklistSubmit({
                ...checklistSubmit,
                checklist: {...checklistSubmit.checklist, tarefas: newCheckListSubmitTarefas},
            })
            
        }
    }

    const handleSaveChecklist = () => {
        var config = {
            headers: { 
                'Content-Type': 'text/plain'
            },
            data : checklistSubmit
        };
        
        console.log(checklistSubmit);
        axios
            .post(baseUrl, JSON.stringify(config.data), {headers: config.headers})
            .then(function (response) {
                setChecklistSubmit(undefined);
            })
            .catch(function (error) {
                console.log(error);
            });
        
        setModalIsOpen(false);
    }

    return (
        <div className='fixed top-0 left-0 right-0 z-50 hidden w-full p-1 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full'>
            <ReactModal 
                className='' 
                isOpen={modalIsOpen} 
                ariaHideApp={false} 
                style={{
                    overlay: {
                        backgroundColor: '#eee'
                    },
                    content: {
                        inset: '10px'
                    }
                }}
            >
                <div className='sticky top-0 z-50 bg-white p-1 flex justify-between'>
                    <h3 className="inline-block text-2xl sm:text-2xl font-extrabold text-slate-900 tracking-tight dark:text-slate-200">{getCurrentChecklist()?.nome}</h3>
                    <button 
                        type="button" 
                        onClick={() => setModalIsOpen(false)} 
                        className="float-right bg-white rounded-md p-1 inline-flex items-center justify-center text-gray-400 hover:text-red-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    >
                        <span className="sr-only">Close menu</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className='relative'>
                    <ul>
                        {getCurrentChecklist()?.tarefas?.map( 
                        (tarefa) => 
                        <li 
                            className="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700 mb-2" 
                            key = { tarefa.item_id }
                        >
                            <input 
                                className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                onChange={(e) => handleCheckTask(e)} 
                                value={tarefa.item_id} 
                                type="checkbox" 
                            />
                            <label 
                                className="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                {tarefa.desc}
                            </label>
                        </li>
                        ) }
                    </ul>
                    
                </div>
                <div className='sticky text-center z-50 bg-white p-2 bottom-0'>
                    <button 
                        className="px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" 
                        onClick={() => handleSaveChecklist()} 
                    >
                        SALVAR
                    </button>
                </div>
            </ReactModal>
        </div>
    );
}

export default ChecklistForm;
